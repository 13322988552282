// FILE: main.js

import { createApp } from "vue"
import { createPinia } from "pinia"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { Quasar, Dialog, Loading, QSpinnerGears } from "quasar"
import iconSet from "quasar/icon-set/mdi-v6"
import { clerkPlugin } from "vue-clerk/plugin"
import { clerk } from "@/services/clerk"
import { createHead } from "@unhead/vue"
const head = createHead()

// Import icon libraries; you can choose different ones!
// See: https://quasar.dev/start/vite-plugin#using-quasar
import "@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css"
import "@quasar/extras/mdi-v6/mdi-v6.css"

// A few examples for animations from Animate.css:
// import @quasar/extras/animate/fadeIn.css
// import @quasar/extras/animate/fadeOut.css
// Import Quasar css
import "quasar/src/css/index.sass"

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from "./App.vue"
import router from "./router"
import "vue3-toastify/dist/index.css"
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify"
import { initSentry } from "./services/sentry"
import { createGtm } from "@gtm-support/vue-gtm"
import { Clarity } from "./types/clarity"
import VueApexCharts from "vue3-apexcharts"

const myApp = createApp(App)
myApp.use(head)

myApp.use(Vue3Toastify, {
   autoClose: 3000,
} as ToastContainerOptions)

myApp.use(Quasar, {
   plugins: { Dialog, Loading }, // import Quasar plugins and add here
   config: {
      loading: {
         delay: 750, // ms
         spinner: QSpinnerGears,
         spinnerColor: "white",
         spinnerSize: 200,
         backgroundColor: "primary",
      },
   },
   iconSet: iconSet,
   /*
     config: {
       brand: {
         // primary: '#e46262',
         // ... or all other brand colors
       },
       notify: {...}, // default set of options for Notify Quasar plugin
       loading: {...}, // default set of options for Loading Quasar plugin
       loadingBar: { ... }, // settings for LoadingBar Quasar plugin
       // ..and many more (check Installation card on each Quasar component/directive/plugin)
     }
     */
})

// Connect Pinia, Vue Router, and Clerk
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
myApp.use(pinia)
myApp.use(router)
myApp.use(clerkPlugin, {
   publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
   //  frontendApi: import.meta.env.VITE_CLERK_FRONTEND_API_HOST,
   //  allowedRedirectOrigins: [import.meta.env.VITE_CLERK_ALLOWED_REDIRECT_ORIGIN],
   //  afterSignInUrl: "/select-project",
   //  afterSignInUrl: import.meta.env.VITE_CLERK_AFTER_SIGN_IN_URL,
   //  afterSignUpUrl: import.meta.env.VITE_CLERK_AFTER_SIGN_UP_URL, // TODO: Use this
   //  signInUrl: import.meta.env.VITE_CLERK_SIGN_IN_URL,
   navigate: (to: string) => {
      router.push(to)
   },
})

if (import.meta.env.VITE_GTM_CONTAINER_ID) {
   myApp.use(
      createGtm({
         id: String(import.meta.env.VITE_GTM_CONTAINER_ID),
         defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
         compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
         nonce: "2726c7f26c", // Will add `nonce` to the script tag
         enabled: import.meta.env.VITE_GTM_CONTAINER_ID ? true : false,
         debug: import.meta.env.VITE_ENV != "production" ? true : false,
         loadScript: true,
         // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
         trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
      }),
   )
}

declare global {
   interface Window {
      clerk: typeof clerk
      commitSHA: string
      clarity: Clarity
   }
}
window.clerk = clerk
if (import.meta.env.VITE_COMMIT_SHA) {
   window.commitSHA = String(import.meta.env.VITE_COMMIT_SHA)
}
initSentry(myApp, router)

myApp.use(VueApexCharts)

// Assumes you have a <div id="app"></div> in your index.html
myApp.mount("#app")
